<template>
  <div id="download" class="big-download-btn" @click="download()">
    <img src="@/assets/gb/downlaod.png" class="dowanlod-img" alt="dowanlod" />
    <div class="dowanlod-name">
      Download
    </div>
  </div>
</template>
<script>
export default {
  name: 'dowanlod',
  props: {
    from: String,
    filename: String,
    isOffical: {
      type: Number,
      default: 0,
    },
    name: String,
  },
  data () {
    return {
      domainMap: {
        gb: {
          domain: "gbwhatsapp.download",
          appName: "GB_gbwhatsapp.download"
        },
        ob: {
          domain: "gbwhatsapp.download",
          appName: "OB_gbwhatsapp.download"
        },
        yo: {
          domain: "gbwhatsapp.download",
          appName: "YO_gbwhatsapp.download"
        },
        plus: {
          domain: "gbwhatsapp.download",
          appName: "PLUS_gbwhatsapp.download"
        },
      }
    }
  },
  methods: {
    download () {
      // if (this.name) {
      //   window.location.href = `/download?apps=${this.name}&from=${this.from}&filename=${this.filename}&isOffical=${(this.isOffical)}&domain=${this.domainMap[this.name].domain}&appName=${this.domainMap[this.name].appName}`
      // } else {
      //   window.location.href = `/download?apps=gb&from=${this.from}&filename=${this.filename}&isOffical=${(this.isOffical)}&domain=gbwhatsapp.download&appName=GB_gbwhatsapp.download`
      // }

      window.location.href = `/download?apps=${this.name}&from=${this.from}&filename=${this.filename}&isOffical=${(this.isOffical)}&domain=gbwhatsapp.download&appName=GB_gbwhatsapp.download`


      this.$logEvent(`click_${this.name}home_download`)
    },
  },
}
</script>
